<template>
    <section id="ficha-tecnica">
        <p><strong><span class="ficha-tecnica__titular">NOMBRE DEL MEDICAMENTO:</span></strong>
            Adiro 100 mg comprimidos gastrorresistentes EFG. Adiro
            300 mg comprimidos gastrorresistentes EFG. 
        </p>

        <p><strong><span class="ficha-tecnica__titular">COMPOSICION CUALITATIVA Y CUANTITATIVA:</span></strong>
            Cada comprimido de Adiro100 mg contiene: ácido acetilsalicílico, 100 mg. Cada comprimido de Adiro 300 mg contiene: ácido acetilsalicílico, 300 mg. 
            Para consultar la lista completa de excipientes, ver sección “Lista de excipientes”. 
        </p>

        <p><strong><span class="ficha-tecnica__titular">FORMA FARMACEUTICA:</span></strong>
            Comprimidos gastrorresistentes.
        </p>

        <p><strong><span class="ficha-tecnica__titular">DATOS CLÍNICOS:</span></strong></p>

        <p><strong><span class="ficha-tecnica__secundario">Indicaciones terapéuticas:</span></strong>
            Está indicado  en adultos para la profilaxis secundaria tras un primer evento isquémico coronario o cerebrovascular de: 
            Infarto de miocardio, Angina estable o inestable, Angioplastia coronaria, Accidente cerebrovascular no hemorrágico transitorio o permanente, 
            Reducción de la oclusión del injerto después de realizar un by-pass coronario. 
        </p>

         <p><strong><span class="ficha-tecnica__secundario">Posología y forma de administración:</span></strong>
            Vía oral. Se recomienda administrar de 100 a 300 mg/día. Los comprimidos deben ingerirse con un vaso de agua, preferentemente en ayunas y al menos 1 hora antes de las comidas. 
            Los comprimidos gastrorresistentes no se deben triturar, romper o masticar para asegurar una liberación en el intestino. 
            Por su forma galénica de liberación diferida, Adiro 100 / 300 no está indicado en el infarto agudo de miocardio. Sin embargo, si en caso de urgencia debe recurrirse a su utilización, se recomienda triturar el comprimido o bien masticarlo y tragarlo 
            con el fin de acelerar la absorción del ácido acetilsalicílico. Dosis superiores no han mostrado mayor eficacia y aumentan el número de complicaciones. Población pediátrica: Este medicamento no debe administrarse a niños menores de 16 años con proceso febriles, 
            gripe o varicela, ya que la ingesta de ácido acetilsalicílico se ha asociado con la aparición del Síndrome de Reye (ver sección “Contraindicaciones”). Pacientes con insuficiencia hepática: Este medicamento está contraindicado en pacientes con insuficiencia hepática grave 
            (ver sección “Contraindicaciones”). Se debe usar con especial precaución en pacientes con la función hepática alterada (ver sección “Advertencias y precauciones especiales de empleo”). Pacientes con insuficiencia renal: Este medicamento está contraindicado en pacientes con 
            insuficiencia renal grave (ver sección “Contraindicaciones”). 
            Se debe usar con especial precaución en pacientes con la función renal alterada ya que el ácido acetilsalicílico puede aumentar más el riesgo de insuficiencia renal y fallo renal agudo (ver sección “Advertencias y precauciones especiales de empleo”). 
        </p>

         <p><strong><span class="ficha-tecnica__secundario">Contraindicaciones:</span></strong>
            No administrar en caso de: Hipersensibilidad al ácido acetilsalicílico, a otros salicilatos, a antiinflamatorios no esteroideos o a la tartrazina (reacción cruzada) 
            o a alguno de los excipientes incluidos en la sección “Lista de excipientes”. Asma. Úlcera gastroduodenal aguda, crónica o recurrente; molestias gástricas de repetición. 
            Antecedentes de hemorragia o perforación gástrica tras el tratamiento con ácido acetilsalicílico u otros antiinflamatorios no esteroideos. Enfermedades que cursen con trastornos de la coagulación, 
            principalmente hemofilia o hipoprotrombinemia. Insuficiencia renal o hepática grave. Insuficiencia cardiaca grave. Tratamiento con metotrexato a dosis de 15 mg/semana o superiores 
            (ver sección “Interacción con otros medicamentos y otras formas de interacción”). Pacientes con pólipos nasales asociados a asma que sean inducidos o exacerbados por el ácido acetilsalicílico. 
            Niños menores de 16 años con procesos febriles, gripe o varicela, ya que en estos casos la ingesta de ácido acetilsalicílico se ha asociado con la aparición del Síndrome de Reye. Tercer trimestre del embarazo. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario">Advertencias y precauciones especiales de empleo:</span></strong>
            El ácido acetilsalicílico debe utilizarse con precaución en caso de hipersensibilidad a los medicamentos analgésicos y antiinflamatorios, antirreumáticos o en caso de otras alergias. 
            El efecto antiagregante plaquetario del ácido acetilsalicílico persiste durante varios días después de su administración, por lo que puede producir una tendencia al sangrado durante y después de intervenciones quirúrgicas. 
            Por ello debe evitarse la administración de ácido acetilsalicílico antes o después de una extracción dental o intervención quirúrgica, siendo conveniente suspender su administración una semana antes de dichas intervenciones. 
            El tratamiento con antiinflamatorios no esteroideos se asocia con la aparición de hemorragia, ulceración y perforación del tramo digestivo alto. Estos episodios pueden aparecer en cualquier momento a lo largo del tratamiento, 
            sin síntomas previos y en pacientes sin antecedentes de trastornos gástricos. El riesgo aumenta con la dosis, en pacientes ancianos y en pacientes con antecedentes de úlcera gástrica, 
            especialmente si se complicó con hemorragia o perforación. Se debe de advertir de estos riesgos a los pacientes, instruyéndoles de que acudan a su médico en caso de aparición de melenas, 
            hematemesis, astenia acusada o cualquier otro signo o síntoma sugerente de hemorragia gástrica. Si aparece cualquiera de estos episodios, el tratamiento debe de interrumpirse inmediatamente. 
            Debe emplearse con precaución en caso de tratamiento concomitante con anticoagulantes (ver sección “Interacción con otros medicamentos y otras formas de interacción”). 
            Siempre que sea posible deberá evitarse el tratamiento concomitante con medicamentos que puedan aumentar el riesgo de hemorragias, especialmente digestivas altas, tales como corticoides, antiinflamatorios no esteroideos, 
            antidepresivos del tipo inhibidores selectivos de la recaptación de serotonina, antiagregantes plaquetarios, anticoagulantes. En el caso de que se juzgue necesario el tratamiento concomitante, éste deberá realizarse con precaución, 
            advirtiendo al paciente de posibles signos y síntomas (melenas, hematemesis, hipotensión, sudoración fría, dolor abdominal, mareos) así como la necesidad de interrumpir el tratamiento y acudir inmediatamente al médico. 
            Algunos antiinflamatorios no esteroideos (AINEs), como ibuprofeno y naproxeno, pueden atenuar el efecto inhibidor sobre la agregación plaquetaria. Se debe aconsejar a los pacientes que estén tomando ácido acetilsalicílico y planeen tomar además AINEs, 
            que consulten a su médico (ver sección “Interacciones con otros medicamentos y otras formas de interacción”). El ácido acetilsalicílico puede producir broncoespasmo e inducir ataques asmáticos u otras reacciones de hipersensibilidad. Los factores de riesgo 
            son: asma pre-existente, fiebre del heno, pólipos nasales o insuficiencia respiratoria crónica. Asimismo, puede darse en pacientes con otras manifestaciones alérgicas, como por ejemplo, reacciones cutáneas, picor o urticaria. Se utilizará con precaución en 
            pacientes con insuficiencia renal o en pacientes con deterioro de la circulación cardiovascular, (por ejemplo, enfermedad vascular renal, insuficiencia cardíaca congestiva, depleción de volumen, cirugía mayor, sepsis o acontecimientos hemorrágicos importantes), 
            ya que el ácido acetilsalicílico puede incrementar el riesgo de insuficiencia renal y fallo renal agudo. En pacientes con déficit de glucosa-6-fosfato deshidrogenasa, el ácido acetilsalicílico puede producir hemólisis o anemia hemolítica. Algunos factores pueden 
            incrementar el riesgo de hemólisis, como por ejemplo, dosis elevadas, fiebre o infecciones agudas. Además, este medicamento deberá administrarse bajo estrecha supervisión médica en caso de: deterioro de la función hepática, hipersensibilidad a otros 
            antiinflamatorios/antirreumáticos, urticaria, rinitis, hipertensión arterial. A dosis bajas, el ácido acetilsalicílico reduce la excreción de ácido úrico, lo que puede desencadenar ataques de gota en algunos pacientes. Advertencias sobre excipientes: 
            Este medicamento contiene menos de 23 mg de sodio (1 mmol) por comprimido; esto es, esencialmente “exento de sodio”. 
        </p>

         <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Población pediátrica:</span></strong>
            Este medicamento no debe administrarse a niños menores de 16 años con proceso febriles, gripe o varicela, ya que la ingesta de ácido acetilsalicílico se ha asociado con la aparición del Síndrome de Reye (ver sección “Contraindicaciones”). 
        </p>

         <p><strong><span class="ficha-tecnica__secundario">Interacción con otros medicamentos y otras formas de interacción:</span></strong></p>

         <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Metotrexato:</span></strong>
            a dosis de 15 mg/semana y superiores: los AINEs disminuyen la secreción tubular de metotrexato incrementando las concentraciones plasmáticas del mismo y por tanto su toxicidad. 
            Por esta razón no se recomienda el uso concomitante con AINEs en pacientes tratados con altas dosis de metotrexato (ver sección “Contraindicaciones”). A dosis inferiores a 15 mg/semana, 
            deberá tenerse en cuenta la interacción entre el metotrexato y los AINEs, especialmente en los pacientes con la función renal alterada. En casos en que sea necesario el tratamiento combinado debería monitorizarse el hemograma y la función renal, 
            especialmente los primeros días de tratamiento. AINEs: la administración simultánea (en el mismo día) de algunos AINEs, como ibuprofeno y naproxeno, puede atenuar la inhibición plaquetaria irreversible inducida por el ácido acetilsalicílico. 
            No se conoce la relevancia clínica de estas interacciones. El tratamiento con algunos AINEs, como ibuprofeno y naproxeno, en pacientes con riesgo cardiovascular aumentado puede limitar la protección cardiovascular del ácido acetilsalicílico 
            (ver sección “Advertencias y precauciones especiales de empleo”). 
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Anticoagulantes, trombolíticos y otros inhibidores de la agregación plaquetaria:</span></strong>
            su administración simultánea aumenta el riesgo de hemorragia. Si resulta imposible evitar una asociación de este tipo, se requiere una monitorización cuidadosa del INR (International Normalized Ratio) (ver sección “Advertencias y precauciones especiales de empleo”). 
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Otros antiinflamatorios no esteroideos (AINEs):</span></strong>
            la administración simultánea de varios AINEs puede incrementar el riesgo de úlceras y de hemorragias gastrointestinales, debido a un efecto sinérgico. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Inhibidores selectivos de la recaptación de serotonina:</span></strong>
            su administración simultánea aumenta el riesgo de hemorragia en general y digestiva alta en particular, por lo que deben evitarse en lo posible su uso concomitante. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Digoxina:</span></strong>
            los AINEs incrementan los niveles plasmáticos de digoxina que pueden alcanzar valores tóxicos. No se recomienda el uso concomitante de digoxina y AINEs. 
            En caso de que su administración simultánea sea necesaria, deben de monitorizarse los niveles plasmáticos de digoxina durante el inicio, ajuste y suspensión del tratamiento con ácido acetilsalicílico. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Antidiabéticos como insulina y sulfonilureas en combinación con ácido acetilsalicílico a dosis más altas:</span></strong>
            la administración concomitante del ácido acetilsalicílico a dosis más altas con insulina y sulfonilureas aumenta el efecto hipoglucemiante de estas últimas.
        </p>

         <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Diuréticos:</span></strong>
            en combinación con dosis altas de ácido acetilsalicílico, puede producirse una disminución de la filtración glomerular por una disminución de la síntesis de prostaglandinas a nivel renal. 
            Los AINEs pueden ocasionar un fallo renal agudo, especialmente en pacientes deshidratados. 
            En caso de que se administren de forma simultánea ácido acetilsalicílico y un diurético, es preciso asegurar una hidratación correcta del paciente y monitorizar la función renal al iniciar el tratamiento. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Glucocorticoides sistémicos, excepto hidrocortisona administrada como terapia de sustitución en la enfermedad de Addison:</span></strong>
            la administración simultánea de ácido acetilsalicílico con glucocorticoides puede incrementar el riesgo de úlceras y de hemorragias gastrointestinales, debido a un efecto sinérgico, 
            por lo que no se recomienda su administración concomitante (ver sección “Advertencias y precauciones especiales de empleo”). 
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Inhibidores de la enzima convertidora de la angiotensina (ECA) y antagonistas de los receptores de la angiotensina II:</span></strong>
            el ácido acetilsalicílico a altas dosis y los antagonistas de la angiotensina II ejercen un efecto sinérgico en la reducción de la filtración glomerular, que puede ser exacerbado en caso de alteración de la función renal. 
            La administración de esta combinación a pacientes ancianos o deshidratados, puede llevar a un fallo renal agudo por acción directa sobre la filtración glomerular. Se recomienda una monitorización de la función renal al iniciar el tratamiento así como una 
            hidratación regular del paciente. Además, esta combinación puede reducir el efecto antihipertensivo de los inhibidores de la ECA y de los antagonistas de los receptores de la angiotensina II, debido a la inhibición de prostaglandinas con efecto vasodilatador. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Ácido valproico:</span></strong>
            la administración conjunta de ácido valproico y acetilsalicílico produce una disminución de la unión a proteínas plasmáticas y una inhibición del metabolismo de ácido valproico.
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Alcohol:</span></strong>
            la administración conjunta de alcohol con ácido acetilsalicílico aumenta el riesgo de hemorragia digestiva. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Uricosúricos:</span></strong>
            la administración conjunta de uricosúricos y ácido acetilsalicílico produce, además de una disminución del efecto de estos últimos, una disminución de la excreción del ácido acetilsalicilico alcanzándose niveles plasmáticos más altos. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Otros antihipertensivos (β-bloqueantes):</span></strong>
            el tratamiento con AINEs puede disminuir el efecto antihipertensivo de los β-bloqueantes debido a una inhibición de las prostaglandinas con efecto vasodilatador. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Ciclosporina:</span></strong>
            los AINEs pueden aumentar la nefrotoxicidad de la ciclosporina debido a efectos mediados por las prostaglandinas renales. Se recomienda una monitorización cuidadosa de la función renal, especialmente en pacientes ancianos. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Vancomicina:</span></strong>
            el ácido acetilsalicílico aumenta el riesgo de otoxicidad de la vancomicina. 
        </p>
        
         <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Interferón α:</span></strong>
            el ácido acetilsalicílico disminuye la actividad del interferón α. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Litio:</span></strong>
            se ha demostrado que los AINEs disminuyen la excreción de litio, aumentando los niveles de litio en sangre, que pueden alcanzar valores tóxicos. No se recomienda el uso concomitante de litio y AINEs. 
            Las concentraciones de litio en sangre deben ser cuidadosamente monitorizadas durante el inicio, ajuste y suspensión del tratamiento con ácido acetilsalicílico, en caso de que esta combinación sea necesaria. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Antiácidos:</span></strong>
            los antiácidos pueden aumentar la excreción renal de los salicilatos por alcalinización de la orina. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Barbitúricos:</span></strong>
            el ácido acetilsalicílico aumenta las concentraciones plasmáticas de los barbitúricos. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Zidovudina:</span></strong>
            el ácido acetilsalicílico puede aumentar las concentraciones plasmáticas de zidovudina al inhibir de forma competitiva la glucuronidación o directamente inhibiendo el metabolismo microsomal hepático. 
            Se debe prestar especial atención a las posibles interacciones medicamentosas antes de utilizar ácido acetilsalicílico, particularmente en tratamiento crónico, combinado con zidovudina. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Fenitoína:</span></strong>
            el ácido acetilsalicílico puede incrementar los niveles plasmáticos de fenitoína. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Metamizol:</span></strong>
            puede reducir el efecto antiagregante plaquetario del ácido acetilsalicílico si se administra concomitantemente. Por consiguiente, esta combinación se debe usar con precaución en pacientes que están tomando dosis bajas de ácido acetilsalicílico como cardioprotector.  
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Interacciones con pruebas de laboratorio:</span></strong>
            El ácido acetilsalicílico puede alterar los valores de las siguientes determinaciones analíticas: Sangre: aumento (biológico) de transaminasas (ALT y AST), fosfatasa alcalina, amoníaco, bilirrubina, colesterol, 
            creatina kinasa, creatinina, digoxina, tiroxina libre, lactato deshidrogenasa (LDH), globulina de unión a la tiroxina, triglicéridos, ácido úrico y ácido valproico; aumento (interferencia analítica) de glucosa, paracetamol y 
            proteínas totales; reducción (bilógica) de tiroxina libre, glucosa, fenitoína, TSH, TSH-RH, tiroxina, triglicéridos, triiodotironina, ácido úrico y del aclaramiento de creatinina; reducción (interferencia analítica) de transaminasas (ALT), 
            albúmina, fosfatasa alcalina, colesterol, creatina kinasa, lactato deshidrogenasa (LDH) y proteínas totales. Orina: Reducción (biológica) de estriol; reducción (interferencia analítica) de ácido 5- hidroxiindolacético, 
            ácido 4hidroxi3metoximandélico, estrógenos totales y glucosa.   
        </p>

         <p><strong><span class="ficha-tecnica__secundario">Fertilidad, embarazo y lactancia:</span></strong></p>

         <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Embarazo:</span></strong>
            La inhibición de la síntesis de prostaglandina puede ocasionar efectos adversos durante el embarazo y/o el desarrollo del embrión/feto. Estudios epidemiológicos han demostrado un incremento del riesgo de abortos y malformaciones 
            relacionadas con el uso de inhibidores de la síntesis de prostaglandina al comienzo del embarazo. Se cree que este riesgo aumenta con la dosis y la duración de la terapia. No existen datos suficientes que puedan avalar o descartar 
            la asociación entre el uso de ácido acetilsalicílico y un incremento del riesgo de producirse un aborto, ni tampoco sobre la relación entre el uso de ácido acetilsalicílico y las malformaciones, aunque no se puede excluir un aumento del 
            riesgo de gastroschisis. En un meta-análisis que incluyó un total de 6 estudios de cohortes, 1 estudio controlado aleatorizado y 15 estudios de caso-control (Kozer et al, 2002) sobre la relación entre malformaciones y el tratamiento con ácido 
            acetilsalicílico durante el primer trimestre de embarazo, no se evidenció un aumento significativo del riesgo de malformaciones (odds ratio = 1,33 OR IC 95%: 0,94 - 1,89). El mayor de los estudios de cohortes (Slone, 1976), 
            incluyó aproximadamente 15.000 mujeres embarazadas que habían tomado ácido acetilsalicílico en el primer trimestre del embarazo. Estudios en animales han mostrado toxicidad reproductiva. Durante el primer y segundo trimestre del embarazo, 
            no se debería administrar ácido acetilsalicílico a menos que sea estrictamente necesario. Si se administra ácido acetilsalicílico a mujeres que estén planificando quedarse embarazadas o durante el primer y segundo trimestre del embarazo, la dosis 
            debería ser lo más baja posible y la duración del tratamiento lo más corto posible. Durante el tercer trimestre del embarazo, debido al uso de inhibidores de la síntesis de prostaglandina, el feto puede estar expuesto a: Toxicidad cardiopulmonar 
            (con cierre prematuro del ductus arteriosus e hipertensión pulmonar). Insuficiencia renal, que puede derivar en fallo renal y oligohidroamniosis. La madre y el niño, al final del embarazo, pueden estar expuestos a: Posible prolongación del tiempo de sangrado, 
            un efecto antiagregante que puede ocurrir incluso a dosis muy bajas. Inhibición de las contracciones uterinas dando lugar a un retraso o prolongación del parto. Por consiguiente, el ácido acetilsalicílico está contraindicado durante el tercer trimestre del embarazo. 
        </p>

         <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Lactancia:</span></strong>
            Los salicilatos y sus metabolitos se excretan a través de la leche materna en pequeñas cantidades. Debido a que no se han observado efectos adversos en el recién nacido tras el uso ocasional de salicilatos, 
            la interrupción de la lactancia no es normalmente necesaria. Sin embargo, si se toman salicilatos de manera regular o a altas dosis, la lactancia debería ser rápidamente interrumpida.
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Fertilidad:</span></strong>
            En base a los limitados datos publicados disponibles, los estudios en humanos no han demostrado un resultado consistente del efecto del ácido acetilsalicílico sobre el deterioro de la fertilidad y no hay evidencia concluyente en estudios con animales. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario">Efectos sobre la capacidad para conducir y utilizar maquinaria:</span></strong> No Procede.</p>

        <p><strong><span class="ficha-tecnica__secundario">Reacciones Adversas:</span></strong> 
            Los efectos adversos del ácido acetilsalicílico son, en general, infrecuentes aunque importantes en algunos casos. En la mayor parte de los casos, los efectos adversos son una prolongación de la acción farmacológica y 
            afectan principalmente al aparato digestivo. El 5-7% de los pacientes experimenta algún tipo de efecto adverso. Las reacciones adversas que se enumeran a continuación se basan en informes poscomercialización con todas las 
            formulaciones de ácido acetilsalicílico y en ensayos clínicos con ácido acetilsalicílico como medicamento de estudio. El cálculo de la frecuencia se basa únicamente en datos del brazo de tratamiento con ácido acetilsalicílico del 
            estudio ARRIVE. Las frecuencias de las reacciones adversas notificadas con ácido acetilsalicílico se resumen en la Tabla 1. Las categorías de frecuencias empleadas se definen de acuerdo con la siguiente convención: frecuentes (≥ 1/100 a &lt; 1/10), 
            poco frecuentes (≥ 1/1.000 a &lt;1/100), raras (≥ 1/10.000 a &lt; 1/1.000). Las reacciones adversas identificadas solo durante la vigilancia poscomercialización, y para las que no se pudo estimar una frecuencia, se recogen como “frecuencia no conocida”. 
        </p>

    <table class="pc">
        <thead>
            <tr>
                <th style="border: 0">Clasificación por órganos y sistemas</th>
                <th style="border: 0">Frecuentes</th>
                <th style="border: 0">Poco frecuentes</th>
                <th style="border: 0">Raras</th>
                <th style="border: 0">Frecuencia no conocida (no puede estimarse a partir de los datos disponibles)</th>
            </tr>
        </thead>
        <tbody>
          <tr>
            <td>Trastornos de la sangre y del sistema linfático</td>
            <td>Hipotrombinemia (con dosis altas)</td>
            <td>Anemia ferropénicaa</td>
            <td>Anemia hemorrágica</td>
            <td>Hemólisis <br> Anemia hemolítica</td>
          </tr>

          <tr>
            <td>Trastornos del sistema inmunológico</td>
            <td></td>
            <td>Hipersensibilidad <br> Hipersensibilidad inducida por fármacos Edema alérgico angioedema</td>
            <td>Reacción anafiláctica</td>
            <td>Shock anafiláctico</td>
          </tr>

          <tr>
            <td>Trastornos del sistema nervioso</td>
            <td>Mareo</td>
            <td>Hemorragia cerebral intracraneal</td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td>Trastornos del oído y del laberinto</td>
            <td>Tinnitus</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td>Trastornos cardiacos</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td>Trastornos vasculares</td>
            <td></td>
            <td>Hematoma</td>
            <td>Hemorragia <br> Hemorragia muscular</td>
            <td>Hemorragia procedimental</td>
          </tr>

          <tr>
            <td>Trastornos respiratorios, torácicos y mediastínicos</td>
            <td>Epistaxis, rinitis</td>
            <td>Congestión nasal</td>
            <td></td>
            <td>Enfermedad respiratoria exacerbada por ácido acetilsalicílico</td>
          </tr>

          <tr>
            <td>Trastornos gastrointestinales</td>
            <td>Dispepsia <br> Dolor gastrointestinal y abdominal Inflamación gastrointestinal <br> Hemorragia del tracto gastrointestinal</td>
            <td>Sangrado gingival Erosión gastrointestinal y úlcera</td>
            <td>Perforación úlcera gastrointestinal</td>
            <td>Enfermedad diafragmática intestinal</td>
          </tr>

          <tr>
            <td>Trastornos hepatobiliares</td>
            <td></td>
            <td>Insuficiencia hepática Hepatitis (particularmente en pacientes con artritis juvenil)</td>
            <td>Incremento transaminasas</td>
            <td></td>
          </tr>

          <tr>
            <td>Trastornos de la piel y del tejido subcutáneo</td>
            <td>Erupción cutánea Prurito</td>
            <td>Urticaria</td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td>Trastornos urinarios y renales</td>
            <td>Hemorragia del tracto urogenital</td>
            <td></td>
            <td>Insuficiencia renal <br> Fallo renal agudo</td>
            <td></td>
          </tr>

          <tr>
            <td>Lesiones traumáticas, intoxicaciones y complicaciones de procedimientos terapéuticos</td>
            <td>Ver	sección	de sobredosis</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

         <tr>
            <td>Trastornos generales</td>
            <td></td>
            <td>Síndrome de Reye (en menores de 16 años con proceso febriles, gripe o varicela. Ver sección 4.3)</td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <table class="movil">
        <thead>
            <tr>
                <th width="40%" style="border: 0">Clasificación por órganos y sistemas</th>
                <th width="30%" style="border: 0">Frecuentes</th>
                <th width="40%" style="border: 0">Poco frecuentes</th>
            </tr>
        </thead>
        <tbody>
          <tr>
            <td>Trastornos de la sangre y del sistema linfático</td>
            <td>Hipotrombinemia (con dosis altas)</td>
            <td>Anemia ferropénicaa</td>
          </tr>

          <tr>
            <td>Trastornos del sistema inmunológico</td>
            <td></td>
            <td>Hipersensibilidad <br> Hipersensibilidad inducida por fármacos Edema alérgico angioedema</td>
          </tr>

          <tr>
            <td>Trastornos del sistema nervioso</td>
            <td>Mareo</td>
            <td>Hemorragia cerebral intracraneal</td>
          </tr>

          <tr>
            <td>Trastornos del oído y del laberinto</td>
            <td>Tinnitus</td>
            <td></td>
          </tr>

          <tr>
            <td>Trastornos cardiacos</td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td>Trastornos vasculares</td>
            <td></td>
            <td>Hematoma</td>
          </tr>

          <tr>
            <td>Trastornos respiratorios, torácicos y mediastínicos</td>
            <td>Epistaxis, rinitis</td>
            <td>Congestión nasal</td>
          </tr>

          <tr>
            <td>Trastornos gastrointestinales</td>
            <td>Dispepsia <br> Dolor gastrointestinal y abdominal Inflamación gastrointestinal <br> Hemorragia del tracto gastrointestinal</td>
            <td>Sangrado gingival Erosión gastrointestinal y úlcera</td>
          </tr>

          <tr>
            <td>Trastornos hepatobiliares</td>
            <td></td>
            <td>Insuficiencia hepática Hepatitis (particularmente en pacientes con artritis juvenil)</td>
          </tr>

          <tr>
            <td>Trastornos de la piel y del tejido subcutáneo</td>
            <td>Erupción cutánea Prurito</td>
            <td>Urticaria</td>
          </tr>

          <tr>
            <td>Trastornos urinarios y renales</td>
            <td>Hemorragia del tracto urogenital</td>
            <td></td>
          </tr>

          <tr>
            <td>Lesiones traumáticas, intoxicaciones y complicaciones de procedimientos terapéuticos</td>
            <td>Ver	sección	de sobredosis</td>
            <td></td>
          </tr>

         <tr>
            <td>Trastornos generales</td>
            <td></td>
            <td>Síndrome de Reye (en menores de 16 años con proceso febriles, gripe o varicela. Ver sección 4.3)</td>
          </tr>
        </tbody>     
      </table>

      <table class="movil">
        <thead>
            <tr>               
                <th width="50%" style="border: 0">Raras</th>
                <th width="50%" style="border: 0">Frecuencia no conocida (no puede estimarse a partir de los datos disponibles)</th>
            </tr>
        </thead>
        <tbody>
          <tr>
            <td>Anemia hemorrágica</td>
            <td>Hemólisis <br> Anemia hemolítica</td>
          </tr>

          <tr>
            <td>Reacción anafiláctica</td>
            <td>Shock anafiláctico</td>
          </tr>

          <tr>
            <td>Hemorragia <br> Hemorragia muscular</td>
            <td>Hemorragia procedimental</td>
          </tr>

          <tr>
            <td></td>
            <td>Enfermedad respiratoria exacerbada por ácido acetilsalicílico</td>
          </tr>

          <tr>
            <td>Perforación úlcera gastrointestinal</td>
            <td>Enfermedad diafragmática intestinal</td>
          </tr>

          <tr>
            <td>Incremento transaminasas</td>
            <td></td>
          </tr>

          <tr>
            <td>Insuficiencia renal <br> Fallo renal agudo</td>
            <td></td>
          </tr>
        </tbody>
      </table>

        <p>
            * ARRIVE es un ensayo clínico patrocinado por Bayer con 6.270 sujetos en el brazo de 100 mg de ácido acetilsalicílico y 6.276 sujetos en el brazo de placebo. La duración media de la exposición al ácido acetilsalicílico 
            fue de 5,0 años con un rango de 0 a 7 años. a En el contexto de sangrado. b En el contexto de formas graves de deficiencia de glucosa-6-fosfato deshidrogenasa (G6PD por sus siglas en inglés). c En los brazos de ácido acetilsalicílico 
            y placebo se notificaron casos mortales/potencialmente mortales con la misma frecuencia, &lt;0,1%. d En el contexto de reacciones alérgicas graves. e En pacientes con casos preexistentes de deterioro de la función renal o alteración de la circulación cardiovascular. 
            Debido a su efecto inhibidor de la agregación plaquetaria, el ácido acetilsalicílico puede asociarse a un riesgo incrementado de sangrado. En caso de hemorragia, puede producirse anemia aguda o crónica o bien anemia por déficit de hierro, con los correspondientes 
            síntomas clínicos como por ejemplo, astenia, palidez, hipoperfusión. Con dosis superiores a las de este preparado en tratamientos prolongados pueden aparecer: Trastornos generales (cefalea), Trastornos del sistema nervioso (mareos), Trastornos psiquiátricos (confusión), 
            Trastornos del oído y del laberinto (tinnitus, sordera), Trastornos de la piel y tejido subcutáneo (sudoración), Trastornos renales y urinarios (insuficiencia renal y nefritis intersticial aguda). El tratamiento debe ser suspendido inmediatamente en el caso de que el 
            paciente experimente algún tipo de sordera, tinnitus o mareos. En pacientes con historia de hipersensibilidad al ácido acetilsalicílico y a otros antiinflamatorios no esteroideos pueden producirse reacciones anafilácticas o anafilactoides. Esto también podría suceder en 
            pacientes que no han mostrado previamente hipersensibilidad a estos fármacos. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Notificación de sospechas de reacciones adversas:</span></strong>
            Es importante notificar sospechas de reacciones adversas al medicamento tras su autorización. Ello permite una supervisión continuada de la relación beneficio/riesgo del medicamento. 
            Se invita a los profesionales sanitarios a notificar las sospechas de reacciones adversas a través del Sistema Español de Farmacovigilancia de medicamentos de Uso Humano: <a href="https://www.notificaram.es">https://www.notificaram.es.</a> 
        </p>

        <p><strong><span class="ficha-tecnica__secundario">Sobredosis:</span></strong> 
            Dada la naturaleza del preparado, la posibilidad de una intoxicación accidental es muy reducida. La sintomatología de la sobredosificación incluye cefalea, 
            mareos, zumbido de oídos, visión borrosa, somnolencia, sudoración, respiración rápida, confusión mental, náuseas, vómitos y ocasionalmente diarrea. 
            El tratamiento es sintomático, incluyendo emésis, lavado gástrico y administración de carbón activado. En casos graves, administración de cantidades adecuadas de líquidos intravenosos. 
            Hemodiálisis en adultos y niños mayores y diálisis peritoneal en lactantes. En el caso de una supuesta sobredosis, el paciente debe mantenerse bajo observación durante por lo menos 24 horas, 
            puesto que durante varias horas pueden no ponerse en evidencia los síntomas y niveles de salicilato en sangre. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario">Lista de excipientes:</span></strong></p>

        <p><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Núcleo:</span>
            Almidón de maíz, polvo de celulosa. 
        </p>

        <p><span class="ficha-tecnica__secundario ficha-tecnica__secundario--underline" >Recubrimiento:</span>
            Copolímero de ácido metacrílico tipo C, dodecilsulfato de sodio, polisorbato 80, talco, citrato de trietilo. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario">Incompatibilidades:</span></strong> No procede</p>

        <p><strong><span class="ficha-tecnica__secundario">Período de validez:</span></strong> 5 años</p>
        
        <p><strong><span class="ficha-tecnica__secundario">Precauciones especiales de conservación:</span></strong> 
            Conservar en el envase original por debajo de 25ºC (blíster de PP/Alu). No conservar a temperatura superior a 30ºC (blíster de PVC/Alu). 
        </p>

        <p><strong><span class="ficha-tecnica__secundario">Naturaleza y contenido del envase:</span></strong> 
           Adiro 100 mg: Blister de PP/Alu. Envase con 30 comprimidos. Blister de PP/Alu. Envase con 60 comprimidos. Blister de PP/Alu. Envase clínico con 100 comprimidos. Blister de PVC/Alu. Envase con 30 comprimidos. Adiro 300 mg: Blister de PP/Alu. Envase con 30 comprimidos. 
           Blister de PP/Alu. Envase con 60 comprimidos. Blister de PP/Alu. Envase clínico con 500 comprimidos. Puede que solamente estén comercializados algunos tamaños de envases. 
        </p>

        <p><strong><span class="ficha-tecnica__secundario">Precauciones especiales de eliminación:</span></strong> 
           La eliminación del medicamento no utilizado y de todos los materiales que hayan estado en contacto con él se realizará de acuerdo con la normativa local. 
        </p>

        <p><strong><span class="ficha-tecnica__titular">TITULAR DE LA AUTORIZACIÓN DE COMERCIALIZACIÓN:</span></strong>
            BAYER HISPANIA, S.L. Avda. Baix Llobregat 3-5 08970 Sant Joan Despí – Barcelona. Medicamento sujeto a prescripción médica. Adiro 100 mg 30 comprimidos: P.V.P. (IVA): 1,45€. 
            Adiro 100 mg 100 comprimidos (envase clínico): P.V.P. (IVA): 2,83€. Adiro 300 mg 30 comprimidos: P.V.P. (IVA): 2,5€. 
        </p>

        <p><strong><span class="ficha-tecnica__titular">NÚMERO(S) DE AUTORIZACIÓN DE COMERCIALIZACIÓN:</span></strong>
            62.825 (Adiro 100 mg). 62.824 (Adiro 300 mg). 
        </p>

        <p><strong><span class="ficha-tecnica__titular">FECHA DE LA PRIMERA AUTORIZACIÓN/RENOVACIÓN DE LA AUTORIZACIÓN:</span></strong>
            Fecha de la primera autorización: 10/diciembre/1999. Fecha de la última renovación: 10/diciembre/2009. 
        </p>

        <p><strong><span class="ficha-tecnica__titular">FECHA DE LA REVISIÓN DEL TEXTO:</span></strong>
            05/2021. La información detallada de este medicamento está disponible en la página Web de la Agencia Española de Medicamentos y Productos Sanitarios (AEMPS) <a href="http://www.aemps.gob.es/">http://www.aemps.gob.es/.</a>
        </p>


    </section>
</template>

<script>
export default {
  name: "FichaTecnica",
};
</script>